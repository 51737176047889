import React from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

import Img1 from '../assets/Index.png'
import Img2 from '../assets/img2.png'
import Img3 from '../assets/puydufou.png'
import Img4 from '../assets/jobquest.png'
import Img5 from '../assets/adventurequest.jpg'
import Img6 from '../assets/gamejam.jpg'

import pdf1 from '../assets/sigean.pdf'
import pdf2 from '../assets/SauvegardeBDD.pdf'
import pdf3 from '../assets/puydufou.pdf'




const Work = () => {
  return (
    <section className='section' id='work'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-10 p-6'>
          <motion.div
              variants={fadeIn('right', 0.2)}
              initial='hidden'
              whileInView={'show'}
              viewport={{once: false, amount: 0.3}}
              className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0 justify-end'>
            <div>
              <h2 className='h2 leading-tight text-accent'>
                Mes projets.
              </h2>
              <h3 className='h3 max-w-sm mb-16'>Voici mes projets les plus récents.</h3>
            </div>
            <a href={pdf1} target='_blank' rel="noreferrer" alt='lien pdf'
               className='group relative overflow-hidden border-2 border-white/50 rounded-xl shadow-lg shadow-cyan-500/50'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              <img className='group-hover:scale-125 transition-all duration-500 w-full' src={Img1} alt=''/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Back Symfony Front Android</span></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>Gestion d'enclos dans un ZOO</span>
              </div>
            </a>
            <a href="https://github.com/benoista/JobQuest" target='_blank' rel="noreferrer" alt='lien pdf'
               className='group relative overflow-hidden border-2 border-white/50 rounded-xl shadow-lg shadow-cyan-500/50'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={Img4} alt=''/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Svelte, ExpressJs</span></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>JobQuest</span>
              </div>
            </a>
            <a href="http://alexandrebenoist.fr/gamejam" target='_blank' rel="noreferrer" alt='lien pdf'
               className='group relative overflow-hidden border-2 border-white/50 rounded-xl shadow-lg shadow-cyan-500/50'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={Img6} alt=''/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Créé en 48h</span></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>CapyGameJam</span>
              </div>
            </a>
          </motion.div>
          <motion.div
              variants={fadeIn('left', 0.3)}
              initial='hidden'
              whileInView={'show'}
              viewport={{once: false, amount: 0.3}}
              className='flex-1 flex flex-col gap-y-10'>
            <a href={pdf2} target='_blank' rel="noreferrer" alt='lien pdf'
               className='group relative overflow-hidden border-2 border-white/50 rounded-xl shadow-lg shadow-cyan-500/50'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={Img2} alt=''/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Vue.JS, YML, PHP, eslint</span></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>Sauvegarde de BDD</span>
              </div>
            </a>
            <a href={pdf3} target='_blank' rel="noreferrer" alt='lien pdf'
               className='group relative overflow-hidden border-2 border-white/50 rounded-xl shadow-lg shadow-cyan-500/50'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={Img3} alt=''/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>MVC</span></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>Puydufou</span>
              </div>
            </a>
            <a href="https://github.com/benoista/AdventureQuest" target='_blank' rel="noreferrer" alt='lien pdf'
               className='group relative overflow-hidden border-2 border-white/50 rounded-xl shadow-lg shadow-cyan-500/50'>
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              <img className='group-hover:scale-125 transition-all duration-500' src={Img5} alt=''/>
              <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Java, Java Swing</span></div>
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>2D Game</span>
              </div>
            </a>
          </motion.div>

        </div>
      </div>
    </section>
  );
};

export default Work;
